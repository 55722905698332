import FooterNavigation from '../components/FooterNavigation'
import { Link, useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import { useCurrentUser } from '../swr/index'
import { useState, useEffect } from 'react'
import SelectProducts from '../components/small/SelectTypeOfProduct'
import { API_URL, POST_FETCH_OPTIONS } from '../common/constants'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationSettingsPicker from '../components/UI/NotificationSettingsPicker'
import { logout } from '../common/helpers';
import OnwayFooter from '../components/UI/OnwayFooter'
import SelectDeliveryCompany from '../components/small/SelectDeliveryCompany'
import axios from 'axios';

export default function SettingsPage() {

	const { mutate } = useSWRConfig();
	const { data, isLoading, isError } = useCurrentUser()

	const [email, setEmail] = useState('')
	const [businessName, setBusinessName] = useState('')
	const [phoneNumbers, setPhoneNumbers] = useState([])
	const [productName, setProductName] = useState('');
	const [instagramAccount, setInstagramAccount] = useState('');
	const [disabled, setDisabled] = useState([]);

	const [deliveryCompany, setDeliveryCompany] = useState('')

	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState('');

	// Code for the delivery company and pricing plan name
	const [allDeliveryCompanies, setAllDeliveryCompanies] = useState([]);
	const [allPricingPlans, setAllPricingPlans] = useState(null);
	const [selectedDeliveryId, setSelectedDeliveryId] = useState('');
	const [selectedPricingId, setSelectedPricingId] = useState('');

	const fetchDeliveryCompanies = async () => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/businesses/delivery_companies`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			setAllDeliveryCompanies(response.data.data);
		} catch (error) {
			console.error('Error fetching delivery companies:', error);
		}
	};

	// Fetch pricing plans function
	const fetchPricingPlans = async () => {
		if (!selectedDeliveryId || !selectedPricingId) return; // Ensure IDs are available

		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/v1/delivery_companies/${selectedDeliveryId}/pricing_plans/${selectedPricingId}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			);
			setAllPricingPlans(response.data.data); // Assuming the pricing plans are under 'data'
		} catch (error) {
			console.error('Error fetching pricing plans:', error);
		}
	};

	useEffect(() => {
		fetchDeliveryCompanies();
	}, []);

	useEffect(() => {
		fetchPricingPlans();
	}, [selectedDeliveryId, selectedPricingId]); // Re-fetch when IDs change
	// Code for the delivery company and pricing plan name Ends Here


	useEffect(() => {
		if (data) {
			setEmail(data.email)
			setBusinessName(data.business_name || '')
			setPhoneNumbers(data.phone_numbers)
			setProductName(data.product_type || '')
			setInstagramAccount(data.instagram)
			setDisabled(data.disabled_notifications)
			setDeliveryCompany(data.delivery_companies?.find(item => true) || "")
			setSelectedDeliveryId(data.deliveryCompanyId);
			setSelectedPricingId(data.pricingPlanId);
		}
	}, [data])



	if (isLoading) return "Loading..."
	if (isError) return 'Error...'



	// Code for the delivery company and pricing plan name
	//console.log("Reached", allDeliveryCompanies);
	// Find selected delivery company name
	const chosenDeliveryCompany = allDeliveryCompanies?.find(company => company.id === selectedDeliveryId);
	const chosenDeliveryName = chosenDeliveryCompany ? chosenDeliveryCompany.name : 'Not selected';

	// Find selected pricing plan name
	// const chosenPricingPlan = allPricingPlans.find(plan => plan.id === selectedPricingId);
	const chosenPricingName = allPricingPlans ? allPricingPlans.name : 'Not selected';
	// Code for the delivery company and pricing plan name Ends Here

	const handleSignUp = async (e) => {
		e.preventDefault()
		const formData = new FormData(e.target);
		const keys = [
			'email',
			// 'password',
			// 'business_name',
			'phone',
			'instagram',
		]

		let data = {}
		keys.forEach(key => data[key] = formData.get(key))
		data.product_type = productName
		data['phone_numbers'] = [data['phone']]
		data['disabled_notifications'] = disabled
		data['delivery_companies'] = [deliveryCompany]

		if (data['email'] === '') {
			toast.error('Email can\'t be empty.')
			return
		}

		try {
			const result = await fetch(API_URL.CURRENT_USER, POST_FETCH_OPTIONS(data)).then(res => res.json())
			result.success ? toast.info('Profile saved.') : toast.error(result.message)
			mutate(API_URL.CURRENT_USER)
		} catch (err) {
			toast.error('We couldn\'t update your profile. Please try again.')
		}
	}

	const changePassword = () => {
		fetch(API_URL.RESET_PASSWORD, POST_FETCH_OPTIONS({ email: data.email, isChange: true }))
			.then(res => res.json())
			.then(data => {
				data.success ? navigate(`/reset?token=${data.token}`) : setErrorMessage(data.message || 'Something went wrong. Please try again.')
			})
			.catch(err => {
				console.log(err)
			})
	}




	return (
		<div>
			<div className="container">
				{/*<ToastContainer />*/}
				<h1 className="is-size-1 has-text-centered my-5">Settings</h1>
				<FooterNavigation activeTab={'settings'} />
				<div className="my-6" />
				<div className="max-width-md mx-auto">
					<form onSubmit={handleSignUp}>
						<label className="label">Email</label>
						<input name="email" value={email || ''} onChange={(e) => setEmail(e.target.value)} className="input mb-5" type="email" />

						{/* Add password change / reset */}
						<label className="label">Password</label>
						<input name="password" placeholder="••••••••" className="input" type="password" disabled />
						<button className="mt-2" onClick={() => changePassword()}>Change password</button>

						<hr />

						<label className="label">Business name</label>
						<input name="business_name" value={businessName || ''} className="input mb-5" type="text" disabled />

						<label className="label">Phone number</label>
						<input name="phone" onChange={(e) => setPhoneNumbers(e.target.value)} value={phoneNumbers || ''} className="input" type="text" />

						<hr />





						<label className="label">Selected Delivery Company and Plan</label>
						<div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
							<p style={{ margin: '0' }}><strong>Company:</strong> {chosenDeliveryName ? chosenDeliveryName : 'Not selected'}</p>
							<p style={{ margin: '0' }}><strong>Plan:</strong> {chosenPricingName ? chosenPricingName : 'Not selected'}</p>
						</div>






						<label className="label">Type of Products</label>
						<div className="mb-4">
							<SelectProducts value={productName} onSelect={(productName) => setProductName(productName)} />
						</div>

						<label className="label">Instagram account</label>
						<input value={instagramAccount || ''} onChange={(e) => setInstagramAccount(e.target.value)} name="instagram" className="input mb-4" type="text" />

						<NotificationSettingsPicker
							disabledNotifications={disabled}
							setDisabled={setDisabled}
						/>

						<input className="my-4 button has-background-grey-light is-fullwidth" type="submit" value="Save" />
					</form>
					<div className="has-text-right mb-4">
						<Link onClick={() => logout()} className="" to="/">Logout</Link>
					</div>
				</div>
			</div>
		</div>
	)
}